import { useWebBuilderState } from "context/webBuilder";
// import UploadFileComponent from "modules/WebBuilder-V2/shared/UploadFile";

// import { ReactComponent as LogoPlaceholder } from "assets/images/webBuilder/logo-placeholder.svg";
// import { useLocation } from "react-router-dom";
import HeaderButtonModal from "./Modals/HeaderButton";
import ChangeLogosModal from './Modals/ChangeLogos';
// import ThemeTwoHeroHeader from './Theme2';
// import ThemeThreeHeroHeader from './Theme3';
import useWBHeader from './useWBHeader';
import HeaderThemeOne from './Theme-1';
import HeaderThemeTwo from './Theme-4';
import HeaderThemeSeven from './Theme-7';
import HeaderThemeFour from './Theme-4';




export default function WebBuilderHeader({ editHeader }) {
	const { style } = useWebBuilderState();
	const {
		onSaveButton,
		saveCompanyLogos,
		toggleButtonModal,
		toggleLogoChangeModal,
		openButtonModal,
		updateHeroHeader,
		changeLogoModal,
		clickContactButton
	} = useWBHeader();


	return (
		<>
			<div className="wb-header-container" onClick={editHeader}>

				{style?.theme === "theme-1" &&
					<HeaderThemeOne
						toggleLogoChangeModal={toggleLogoChangeModal}
						updateHeroHeader={updateHeroHeader}
						clickContactButton={clickContactButton}
					/>
				}

				{style?.theme === "theme-2" &&
					<HeaderThemeTwo
						toggleLogoChangeModal={toggleLogoChangeModal}
						updateHeroHeader={updateHeroHeader}
						clickContactButton={clickContactButton}
					/>
				}

				{style?.theme === "theme-3" &&
					<HeaderThemeOne
						toggleLogoChangeModal={toggleLogoChangeModal}
						updateHeroHeader={updateHeroHeader}
						clickContactButton={clickContactButton}
					/>
				}
				{style?.theme === "theme-4" &&
					<HeaderThemeFour
						toggleLogoChangeModal={toggleLogoChangeModal}
						updateHeroHeader={updateHeroHeader}
						clickContactButton={clickContactButton}
					/>
				}

				{style?.theme === "theme-7" &&
					<HeaderThemeSeven
						toggleLogoChangeModal={toggleLogoChangeModal}
						updateHeroHeader={updateHeroHeader}
						clickContactButton={clickContactButton}
					/>
				}
			</div>

			{/* contact us button modal */}
			{openButtonModal &&
				<HeaderButtonModal
					save={onSaveButton}
					isOpen={openButtonModal}
					toggle={toggleButtonModal}
				/>
			}

			{/* logo modal */}
			{changeLogoModal &&
				<ChangeLogosModal
					isOpen={changeLogoModal}
					toggle={toggleLogoChangeModal}
					save={saveCompanyLogos}
				/>
			}
		</>
	);
}
