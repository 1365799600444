import LocationMarkerIcon from "modules/WebBuilder-V2/shared/icons/LocationMarker";
import StarIcon from "modules/WebBuilder-V2/shared/icons/Star";
import PackageTypeIcon from "modules/WebBuilder-V2/shared/icons/PackageTypeIcon";
import CalendarIcon from "modules/WebBuilder-V2/shared/icons/Calendar";
import ArrowRightIcon from "modules/WebBuilder-V2/shared/icons/ArrowRight";
import { ReactComponent as AddIcon } from "assets/images/webBuilder/add-square40.svg";
import {
	Link,
	useLocation,
	useParams,
} from "react-router-dom/cjs/react-router-dom";
import moment, { now } from "moment";

export default function ThemeSevenPackageCard(props) {
	const {
		// onClick,
		image,
		name,
		departure_date_at,
		return_date_at,
		stars,
		product_uuid,
		hotelName,
		price,
		locationName,
		openEditModal,
		initialName,
		currency,
	} = props;


	const location = useLocation();
	const preview = !location.pathname.includes("edit");
	const params = useParams();
	const packageUrl = `/${params.bundle}/web-builder-v2/preview/package/${product_uuid}`;

	return (
		<div className="theme_package_card">
			<div className="image">
				{image && (
					<Link to={packageUrl}>
						<img src={image} alt={name} />
					</Link>
				)}
				{!preview && !image ? (
					<button
						className="btn add-service-btn"
						onClick={() => openEditModal(props)}
					>
						<img
							src="https://back-sbs.staging.safa.asia/api/v1/files/fetch/1847cef6-e5c0-40bb-b1dd-d2dc7827cd58"
							alt="Safa Visa"
						/>
						<AddIcon />
					</button>
				) : null}
			</div>
			<div className="content">
				<div className="location">
					<p className="destination_name">
						<LocationMarkerIcon />
						<span>{locationName}</span>
						{/* <span className="mx-1">/</span>
						<span>Rome</span> */}
					</p>
				</div>
				<div className="package_name">
					<h5 className="package_title">
						<a href="/">{name || initialName}</a>
					</h5>
					<div className="package_rating">
						{[...Array(5)].map((i, index) =>
							index < stars ? (
								<StarIcon fill="#F5B950" width="16" height="14" />
							) : (
								<StarIcon fill="#DDDCDC" width="16" height="14" />
							)
						)}
					</div>
				</div>
				<div className="package_meta">
					<div className="hotel_name">
						<PackageTypeIcon />
						<span className="mx-1"></span>
						<span>{hotelName || "-"}</span>
					</div>
					<div className="package_date">
						<CalendarIcon />
						<span className="mx-1"></span>
						<span>
							{`${moment(departure_date_at || now()).format(
								"DD MMM YYYY"
							)} - ${moment(return_date_at || now()).format("DD MMM YYYY")}`}
						</span>
					</div>
				</div>
				<div className="package_footer">
					<div className="pricing">
						<p className="from_text">From</p>
						<p className="actual_price">
							{price} <span>{currency}</span>
						</p>
					</div>
					<div className="cta">
						<Link to={packageUrl} className="theme_btn">
							<span className="btn_content">
								<ArrowRightIcon className="mirror-rtl" />
							</span>
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
}
