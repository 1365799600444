import React, { useState } from 'react';
import theme1Src from "assets/images/webBuilder/template/1.png"
import theme2Src from "assets/images/webBuilder/template/2.png"
import theme3Src from "assets/images/webBuilder/template/3.jpg"
import theme4Src from "assets/images/webBuilder/template/4.png"
import theme7Src from "assets/images/webBuilder/template/7.png";
import { useWebBuilderDispatch, useWebBuilderState } from 'context/webBuilder';
import { AlertCircleIcon } from 'modules/UmrahOperations/shared/Icons'
import { Modal, ModalBody } from 'reactstrap';
import { setPageBuilder } from 'services/webBuilder';
import { useHistory, useParams } from 'react-router-dom';
import template1 from "../../Templates/template1.json";
import template2 from "../../Templates/template2.json";
import template3 from "../../Templates/template3.json";
import template4 from "../../Templates/template4.json";
import template7 from "../../Templates/template7.json";
import { changeBorder, changeColorTheme, changeFont } from 'modules/WebBuilder-V2/helpers/styles';
import SelectLanguagesModal from 'modules/WebBuilder-V2/shared/ChooseLanguagesModal';
import { languagesLookup } from 'modules/WebBuilder-V2/ChooseTemplate';
import { formatPageData } from 'modules/WebBuilder-V2/helpers/formatPageData';

export default function ChangeTheme() {
  const history = useHistory();
  const webBuilderData = useWebBuilderState();
  const { bundle } = useParams();

  const themes = [
    // {
    //   id: "old", name: "Customize your website", src: imageCustomize, initialData: templateOld, url: `/${bundle}/web-builder-v1/edit`
    // },
    { id: "theme-1", name: "Theme - Style 1", src: theme1Src, initialData: template1, url: `/${bundle}/web-builder-v2/edit` },
    { id: "theme-2", name: "Theme - Style 2", src: theme2Src, initialData: template2, url: `/${bundle}/web-builder-v2/edit` },
    { id: "theme-3", name: "Theme - Style 3", src: theme3Src, initialData: template3, url: `/${bundle}/web-builder-v2/edit` },
    { id: "theme-4", name: "Theme - Style 4", src: theme4Src, initialData: template4, url: `/${bundle}/web-builder-v2/edit` },
    { id: "theme-7", name: "Theme - Style 7", src: theme7Src, initialData: template7, url: `/${bundle}/web-builder-v2/edit` },
  ];


  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [selectedTheme, setSelectedTheme] = useState(null);
  const [languagesModal, setLanguagesModal] = useState({
    isOpen: false,
    languagesList: [],
    defaultLanguage: null
  });

  const dispatch = useWebBuilderDispatch()

  function toggleLanguagesModal(selectedLanguages, defaultLanguage) {
    setLanguagesModal({
      isOpen: !languagesModal.isOpen,
      languagesList: selectedLanguages || [],
      defaultLanguage: defaultLanguage || null
    })
  }

  function updateStyle({ name, value }) {
    dispatch({
      type: 'updateStyle',
      payload: {
        name,
        value
      }
    })
  }

  function toggleConfirmModal() {
    setConfirmModalOpen(!confirmModalOpen);
  }

  function chooseLanguages() {
    const languagesList = languagesModal.languagesList;
    const page_builder_config = formatPageData({
      contentData: webBuilderData,
      languagesList,
      defaultLanguage: languagesModal.defaultLanguage,
      themeConfig: selectedTheme.initialData
    })
    dispatch({
      type: 'setInitialData',
      payload: page_builder_config
    });
    toggleLanguagesModal(null)
  }

  async function confirmSelectNewTheme() {
    if (selectedTheme) {
      const languagesList = webBuilderData.languages;

      const page_builder_config = formatPageData({
        contentData: selectedTheme.initialData,
        languagesList,
        defaultLanguage: webBuilderData.defaultLanguage,
        themeConfig: selectedTheme.initialData
      });

      const res = await setPageBuilder({ page_builder_config });
      if (res?.status === 200) {
        let pageBuilderData = page_builder_config;
        dispatch({
          type: 'setInitialData',
          payload: pageBuilderData
        });

        const style = pageBuilderData?.style
        changeColorTheme(style?.color, updateStyle)
        changeBorder(style?.rounded, updateStyle)
        changeFont(style?.font, updateStyle)
        toggleConfirmModal();
        history.push(selectedTheme.url);
      }
    }
  }


  return (
    <div className="sidebar-theme">
      <div className="content-header">
        <h4>Thems</h4>
      </div>
      <div className="themes-container">
        {themes.map(theme => {
          const isSelected = webBuilderData?.style?.theme === theme.id;
          return (
            <div key={theme.id} className={`theme-wrapper ${isSelected ? "active" : "pointer"}`}>
              <img src={theme.src} alt={theme.name} />
              <p className="mt-2 h6 ps-2">{theme.name}</p>
              <div className="overlay">
                {isSelected ?
                  <button className="btn"
                    onClick={() => {
                      setSelectedTheme(theme);
                      toggleLanguagesModal(webBuilderData.languages, webBuilderData.defaultLanguage)
                    }}
                  >
                    Choose languages
                  </button>
                  :
                  <button className="btn"
                    onClick={() => {
                      setSelectedTheme(theme);
                      toggleConfirmModal();
                      // toggleLanguagesModal(webBuilderData.languages, webBuilderData.defaultLanguage)
                    }}
                  >
                    Change theme
                  </button>
                }

              </div>
            </div>
          )
        })}

      </div>


      {confirmModalOpen ?
        <Modal className="confirm-theme-modal" isOpen={confirmModalOpen} centered>
          <ModalBody>
            <div className="d-flex flex-column gap-10 align-items-start">
              <span className="icon mb-2">
                <AlertCircleIcon color="#D92D20" />
              </span>
              <h6 className="header">Change Theme</h6>
              <p className="info-text">Change theme will delete all your saved data. </p>
              <p className="font-weight-bold">Are you sure changing theme?</p>
            </div>
            <div className="d-flex gap-10 mt-4">
              <button className="btn reject-btn" onClick={toggleConfirmModal}>No</button>
              <button className="btn confirm-btn" onClick={confirmSelectNewTheme}>Yes</button>
            </div>
          </ModalBody>
        </Modal>

        :
        null
      }

      <SelectLanguagesModal
        isOpen={languagesModal.isOpen}
        toggle={toggleLanguagesModal}
        setLanguagesModal={setLanguagesModal}
        languagesList={languagesModal.languagesList}
        defaultLanguage={languagesModal.defaultLanguage}
        selectTemplateConfig={chooseLanguages}
        languagesLookup={languagesLookup}
      />
    </div>
  )
}
