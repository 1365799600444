import LocationMarkerIcon from 'modules/WebBuilder-V2/shared/icons/LocationMarker';
import { ReactComponent as AddIcon } from "assets/images/webBuilder/add-square40.svg";
import usePreviewMode from 'hooks/usePreviewMode';
import DragAndDropContainer from 'modules/WebBuilder-V2/shared/DragAndDropContainer';
import { SORT_ITEMS_IN_CONTAINER_ACTION } from 'modules/WebBuilder-V2/interfaces/blockTypes';
import { EditBox } from 'modules/WebBuilder-V2/shared/EditBox';

const toursBlocksType = "toursBlocksType";


export default function ThemeSevenTourCard(props) {

  let {
    openEditModal,
    name,
    price,
    image,
    currency,
    locationName,
    city,
    product_uuid,
    initialName,
    index,
    sortToursItems,
    focusContainer,
    editItem,
    itemsBoxActions,
    type
  } = props;

  const { isPreview } = usePreviewMode();
  

  return (
    <DragAndDropContainer
      accept={toursBlocksType}
      type={toursBlocksType}
      id={props?.id}
      index={index}
      action={SORT_ITEMS_IN_CONTAINER_ACTION}
      moveItemInContainer={(dragIndex, hoverIndex) =>
        sortToursItems({ dragIndex, hoverIndex })
      }
      key={props?.id || index}

    >
      <EditBox
        editMode={focusContainer === type}
        actions={[
          ...editItem(props, index),
          ...itemsBoxActions(props?.id),
        ]}
      >
        <div className={`theme_tour_card ${props?.cardStyleType || ''} ${!product_uuid ? "empty-service" : ""}`}>
          <div className="image">
            {/* <a href="/">
          <img src="https://back-sbs.staging.safa.asia/api/v1/files/fetch/0b342f56-0067-4e51-a252-dc06a631b308" alt="Safa Visa" />
        </a> */}

            <div className={`service-img`}>
              {!product_uuid && !isPreview ? (
                <button
                  className="btn add-service-btn"
                  onClick={() => openEditModal({ ...props, index })}
                >
                  <AddIcon />
                </button>
              ) : null}

              {image ? (
                <img src={image} alt="Safa Visa" />
              ) : (
                <img src="https://back-sbs.staging.safa.asia/api/v1/files/fetch/0b342f56-0067-4e51-a252-dc06a631b308"
                  alt="Safa Visa" />
              )}
            </div>
          </div>

          <div className="content_overlay">
            <div className="text_wrapper">
              <h5 className="title">
                {/* <a href="/">Cairo Tower</a> */}
                <span>{name || initialName}</span>
              </h5>
              <p className='destination_name'>
                <LocationMarkerIcon />
                <span>{locationName}</span>
                {city &&
                  <>
                    <span className='mx-1'>/</span>
                    <span>{city}</span>
                  </>
                }
              </p>
            </div>
            <div className="price_wrapper">
              <p className="from_text">From</p>
              <p className="actual_price">
                {price} <span>{currency}</span>
              </p>
            </div>
          </div>
        </div>
      </EditBox>
    </DragAndDropContainer>
  )
}