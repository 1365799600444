import usePreviewMode from "hooks/usePreviewMode";
import { ReactComponent as TrashIcon } from "assets/images/hotelAccommodation/trash.svg";
import { useWebBuilderDispatch, useWebBuilderState } from "context/webBuilder";
import { SORT_ITEMS_IN_CONTAINER_ACTION } from "modules/WebBuilder-V2/interfaces/blockTypes";
import { AddElement } from "modules/WebBuilder-V2/shared/AddElement";
import AddService from "modules/WebBuilder-V2/shared/AddService";
import DragAndDropContainer from "modules/WebBuilder-V2/shared/DragAndDropContainer";
import { EditBox } from "modules/WebBuilder-V2/shared/EditBox";
import ServicesContent from "modules/WebBuilder-V2/shared/ServicesContent";
import { useCallback, useState } from "react";
import { ReactComponent as SettingIcon } from "assets/images/webBuilder/setting-224.svg";
import Locale from "translations";
import { ReactComponent as RightICon } from "assets/images/webBuilder/right.svg";
import { ReactComponent as LeftIcon } from "assets/images/webBuilder/left.svg";
import ThemeSevenPackageCard from "./PackageCard";
import Carousel from "react-multi-carousel";

const packagesBlocksType = "packagesBlocksType";

export default function ThemeSevenPackages({
	details,
	onFocus,
	focusContainer,
}) {
	const { isPreview } = usePreviewMode();
	const { selectedLanguage } = useWebBuilderState();
	const { id, title, body, items } = details;
	const [openAdd, setOpenAdd] = useState(false);
	const [updateItem, setUpdateItem] = useState(null);
	const dispatch = useWebBuilderDispatch();
	const { webBuilder } = Locale;

	const responsive = {
		superLargeDesktop: {
			breakpoint: { max: 4000, min: 3000 },
			items: 4,
		},
		desktop: {
			breakpoint: { max: 3000, min: 1200 },
			items: 3,
		},
		tablet: {
			breakpoint: { max: 1200, min: 464 },
			items: 2,
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 1,
		},
	};

	const CustomRightArrow = ({ onClick }) => (
		<button
			className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right"
			onClick={onClick}
		>
			<RightICon />
		</button>
	);

	const CustomLeftArrow = ({ onClick }) => (
		<button
			className="react-multiple-carousel__arrow react-multiple-carousel__arrow--left"
			onClick={onClick}
		>
			<LeftIcon className="r-left-icon" />
		</button>
	);

	const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
		return (
			<div className="carousel-button-group">
				<CustomLeftArrow onClick={() => previous()} />
				<CustomRightArrow onClick={() => next()} />
			</div>
		);
	};

	const handleOpenAddModal = useCallback(() => {
		setOpenAdd((prev) => !prev);
		setUpdateItem(null);
	}, []);

	const openEditModal = (item) => {
		handleOpenAddModal();
		setUpdateItem(item);
	};

	const editItem = (item, index) => {
		return [
			<span
				key={`${item.id}-${index}`}
				onClick={() => openEditModal({ ...item, index })}
				className="pointer-event"
			>
				<SettingIcon />
			</span>,
		];
	};

	const addItemToPackages = useCallback(
		(item) => {
			updateItem
				? dispatch({
						type: "editItemInContainer",
						payload: {
							id,
							item,
							itemIndex: updateItem.index,
						},
				  })
				: dispatch({
						type: "addItemToContainer",
						payload: {
							id,
							item,
						},
				  });
			setUpdateItem(null);
		},
		[dispatch, id, updateItem]
	);

	const deleteBodyContent = ({ itemId, deleteType }) => {
		dispatch({
			type: "deleteBodyContent",
			payload: {
				id,
				deleteType,
				itemId,
			},
		});
	};

	const sortPackagesItems = ({ dragIndex, hoverIndex }) => {
		dispatch({
			type: "sortItemsInContainer",
			payload: {
				id,
				dragIndex,
				hoverIndex,
			},
		});
	};
	const updatePackages = ({ name, value, language }) => {
		dispatch({
			type: "updateBodyContent",
			payload: {
				name,
				value,
				id,
				language,
			},
		});
	};
	const itemsBoxActions = (itemId) => {
		return [
			<span
				key={itemId}
				onClick={() =>
					deleteBodyContent({ itemId, deleteType: "container_item" })
				}
				className="pointer-event"
			>
				<TrashIcon />
			</span>,
		];
	};

	const containerBoxActions = [
		// <span
		// 	onClick={() => deleteBodyContent({ deleteType: "container" })}
		// 	className="pointer-event"
		// >
		// 	<TrashIcon />
		// </span>,
	];

	const selectedIds = items ? items.map((i) => i.id) : []



  
	return (
		<EditBox editMode={focusContainer === id} actions={containerBoxActions}>
			<section
				className={`theme_packages_section section_padding__md ${
					isPreview ? "" : "edit_mode"
				}`}
				onClick={() => onFocus(id)}
				id={id}
			>
				<div className="row d-flex align-items-center">
					<div className="col-xl-3 col-lg-4">
						<div className="service-top-section">
							<div className="section_heading w-100">
								<ServicesContent
									header={title}
									body={body}
									changeHeader={(value) =>
										updatePackages({
											name: "title",
											value,
											language: selectedLanguage,
										})
									}
									changebody={(value) =>
										updatePackages({
											name: "body",
											value,
											language: selectedLanguage,
										})
									}
								/>
							</div>

							<AddElement
								onClick={handleOpenAddModal}
								title={webBuilder.addMorePackages}
								className="add-element-box-packages"
							/>
						</div>
					</div>

					<div className="col-xl-9 col-lg-8">
						<div className="section_content">
							<Carousel
								responsive={responsive}
								slidesToSlide={1}
								keyBoardControl={true}
								removeArrowOnDeviceType={["tablet", "mobile"]}
								customButtonGroup={<ButtonGroup />}
								renderButtonGroupOutside={true}
								showDots={true}
								arrows={false}
								infinite={false}
								draggable={false}
							>
								{items.map((props, index) => (
									<DragAndDropContainer
										accept={packagesBlocksType}
										type={packagesBlocksType}
										id={props.id}
										index={index}
										action={SORT_ITEMS_IN_CONTAINER_ACTION}
										moveItemInContainer={(dragIndex, hoverIndex) =>
											sortPackagesItems({ dragIndex, hoverIndex })
										}
										key={props.id}
										className="our-service-item"
									>
										<EditBox
											editMode={focusContainer === id}
											actions={[
												...editItem(props, index),
												...itemsBoxActions(props.id),
											]}
										>
											<ThemeSevenPackageCard
												{...props}
												key={index}
												openEditModal={openEditModal}
											/>
										</EditBox>
									</DragAndDropContainer>
								))}
							</Carousel>
						</div>
					</div>
				</div>
			</section>

			{openAdd && (
				<AddService
					handleModalState={handleOpenAddModal}
					open={openAdd}
					label={webBuilder.packages}
					header={webBuilder.package}
					onAdd={addItemToPackages}
					details={updateItem}
					selectedIds={selectedIds}
				/>
			)}
		</EditBox>
	);
}
