import Locale from 'translations';
import { ReactComponent as MarketplaceIcon } from "assets/images/dashboard/marketplace-icon.svg";
import { ReactComponent as SafaVisaIcon } from "assets/images/dashboard/safa-visa-icon.svg";
import { ReactComponent as WebsiteIcon } from "assets/images/dashboard/website-builder-icon.svg";
import { ReactComponent as BookingCRMIcon } from "assets/images/dashboard/booking-crm-icon.svg";
import { ReactComponent as ApiIntegrationIcon } from "assets/images/dashboard/api-integration-icon.svg";
import { ReactComponent as BravoIcon } from "assets/images/dashboard/bravo-icon.svg";
import { ReactComponent as CRMICON } from "assets/images/subscription-plan/crm.svg"
import { ReactComponent as WebsiteBuilderIcon } from "assets/images/dashboard/website-builder.svg"
import { ReactComponent as TripPlannerIcon } from "assets/images/dashboard/trip-planner.svg"
import { ReactComponent as TripManagementIcon } from "assets/images/subscription-plan/trip-management.svg"
import { ReactComponent as ArrivalNotificationIcon } from "assets/images/subscription-plan/arrival-notification.svg"
import { ReactComponent as OperationCenterIcon } from "assets/images/subscription-plan/operation-center.svg"
import { ReactComponent as LogisticsIcon } from "assets/images/subscription-plan/logistics.svg"
import { ReactComponent as AccountingIcon } from "assets/images/subscription-plan/accounting.svg"
import { ReactComponent as AgentIcon } from "assets/images/subscription-plan/agent.svg"
import { ReactComponent as PackagePlannerIcon } from "assets/images/subscription-plan/package-planner.svg"
import { useSBSDispatch } from 'context/global';
import { getDynamicNav } from 'services/profile';


export default function useNavigationBar() {
  const {
    dashboard,
    operationStatement,
    productsBuilder,
    marketPlace,
    CRM,
    movementManagement,
    operationAccounting,
    ordersViolations,
    guideRequests,
    subscriptionModule,
    teamManagement
  } = Locale;
  const dispatch = useSBSDispatch()
  // operation_timing_setting

  async function fetchDynamicNav() {
    const { data } = await getDynamicNav();
    let packages = data?.packages;
    // adding timing setting module to bravo
    packages?.find(p => p?.category === "bravo")?.modules?.push({ key: "operation_timing_setting", name: operationStatement.timingSettings })
    const staticTabs = mapStaticTabs(data.tabs || []);
    const dynamicTabs = mapDynamicTabs(packages || []);

    dispatch({
      type: "dynamicNav",
      payload: [...staticTabs, ...dynamicTabs].filter(navItem => navItem.modules.length > 0)
    })
  }

  let tabsIcons = {
    "marketplace": <MarketplaceIcon />,
    "safa_offline": <SafaVisaIcon />,
    "bravo": <BravoIcon />,
    "website": <WebsiteIcon />,
    "booking-engine-crm": <BookingCRMIcon />,
    "api-integration": <ApiIntegrationIcon />,
  }

  let staticModulesRoutes = [
    {
      parentKey: "marketplace",
      route: "/market-place-new/global/hotel",
      aliasRoute: "global",
      name: marketPlace.globalDestinations,
    },
    {
      parentKey: "marketplace",
      route: "/market-place-new/umrah/hotel",
      aliasRoute: "umrah",
      name: marketPlace.saudiDestinations
    },
    {
      parentKey: "marketplace",
      route: "/inventory",
      aliasRoute: "inventory",
      name: dashboard.messages.myBookings,
    },
    // safa offline routes
    {
      parentKey: "safa_offline",
      route: "/visa",
      name: marketPlace.groups
    },
    {
      parentKey: "safa_offline",
      route: "/safa_offline/products-builder?version=2",
      aliasRoute: "/safa_offline/products-builder",
      name: productsBuilder.productBuilder
    },
    {
      parentKey: "safa_offline",
      route: "/safa_offline/operation-statement",
      aliasRoute: "/safa_offline/operation-view",
      name: operationStatement.operatingStatement
    },
    {
      parentKey: "safa_offline",
      route: "/safa_offline/agents",
      aliasRoute: "/safa_offline/agensts",
      name: operationStatement.agents
    },
    {
      parentKey: "safa_offline",
      route: "/safa_offline/subscription",
      name: teamManagement.Subscription
    },
  ]


  let dynamicModulesRoutes = {
    crm: {
      route: "/crm/main",
      aliasRoute: "/crm",
      name: CRM.cRM,
      icon: <CRMICON />
    },
    website_builder: {
      route: "/web-builder/select",
      aliasRoute: "/web-builder",
      name: subscriptionModule.WebsiteBuilder,
      icon: <WebsiteBuilderIcon />
    },
    package_planner: {
      route: "/trip-planner",
      name: dashboard.messages.packagePlanner,
      icon: <PackagePlannerIcon />
    },
    trip_builder: {
      route: "/products-builder?version=2",
      aliasRoute: "/products-builder",
      name: productsBuilder.productBuilder,
      icon: <TripManagementIcon />
    },
    operation_statement: {
      route: "/operations/operation-statement",
      aliasRoute: "/operations/operation-view",
      name: operationStatement.operatingStatement,
      icon: <ArrivalNotificationIcon />
    },
    operation_center: {
      route: "/operations/operations-center",
      name: operationStatement.operationsCenter,
      icon: <OperationCenterIcon />
    },
    delegation_management: {
      route: "/operations/movements/delegations-management",
      name: movementManagement.movement,
      icon: <LogisticsIcon />
    },
    official_management: {
      route: "/operations/movements/official-movement",
      name: movementManagement.delegatesManagement,
      icon: <LogisticsIcon />
    },
    operation_accounting: {
      route: "/operations/accounting/operation",
      name: operationAccounting.operationAccounting,
      icon: <AccountingIcon />
    },
    agent_accounting: {
      route: "/operations/accounting/agent",
      name: operationAccounting.agentAccounting,
      icon: <AccountingIcon />
    },
    order_violation: {
      route: "/operations/orders-violations",
      name: ordersViolations.ordersViolations,
      icon: <AccountingIcon />
    },
    guide_request: {
      route: "/operations/guide-requests",
      name: guideRequests.guideRequests,
      icon: <TripPlannerIcon />
    },
    operation_agent: {
      route: "/operations/agents",
      name: operationStatement.agents,
      icon: <AgentIcon />
    },
    operation_setting:
    {
      route: "/operations/settings/basic-settings",
      name: operationStatement.basicSettings,
      icon: <TripPlannerIcon />
    },
    operation_timing_setting: {
      route: "/operations/settings/timing-settings",
      name: operationStatement.timingSettings,
      icon: <TripPlannerIcon />
    },

  }


  function mapStaticTabs(tabs = []) {
    return tabs.map(tab => {
      return {
        key: tab?.key,
        icon: tabsIcons[tab?.key],
        name: tab?.key === "marketplace" ? dashboard.messages.marketPlace : dashboard.messages.SafaOffline,
        modules: staticModulesRoutes?.filter(tabModule => tabModule.parentKey === tab.key),
        isStatic: true,
        isSafaConnected: tab?.connected,
        route: !tab?.connected && tab?.key === "safa_offline" ? "/plan-details/safa_offline" : null,
        is_subscribed: tab?.key === "safa_offline" ? tab?.connected : true,
        category: tab?.key
      }
    })
  }

  function mapDynamicTabs(tabs) {
    return tabs.map(tab => {
      return {
        ...tab,
        key: tab?.key || tab?.id,
        icon: tabsIcons[tab?.category],
        name: tab?.title,
        modules: tab?.is_subscribed
          ? tab?.modules?.map(tabModule => (
            {
              ...dynamicModulesRoutes[tabModule?.key],
              parentKey: tab?.key || tab?.id,
              name: tabModule?.name,
              //handle intersicted routes  (trip builder and website builder are in more than one bundle)
              // so the module route will (bundle or tab) cateogry then the module route 
              // we do all of that to get the right active tab  
              route: tabModule?.key === "trip_builder" || tabModule?.key === "website_builder"
                ? `/${tab?.category}${dynamicModulesRoutes[tabModule?.key]?.route}`
                : dynamicModulesRoutes[tabModule?.key]?.route,
            }
          )).filter(tabModule => tabModule.route)
          : tab?.modules,
        route: tab?.is_subscribed ? null : `/plan-details/${tab?.category}`,
        is_subscribed: tab?.is_subscribed,
      }
    })
  }


  return {
    mapStaticTabs,
    mapDynamicTabs,
    dynamicModulesRoutes,
    tabsIcons,
    fetchDynamicNav
  }
}